.ant-table-tbody > tr > td {
  border-bottom: 0px solid #f0f0f0;
}

.store-dashboard-block {
}

.store-dashboard-block .store-information {
  width: 100%;
  margin: 0 0 20px;
}

.store-dashboard-block .store-information .title-information {
  margin: 0 0 10px;
}

.store-dashboard-block .store-information .title-information h1 {
  font-size: 22px;
  margin: 0;
}

.store-dashboard-block .store-information .store-litemist {
}

.store-dashboard-block .store-information .store-items {
  background: white;
  padding: 10px 8px 10px;
  border-radius: 6px;
  box-shadow: 2px 2px 14px 1px rgb(0 0 0 / 5%);
  margin: 0 0 4px;
}

.store-dashboard-block .store-information .store-items h5 {
  font-size: 20px;
  margin: 0 0 8px;
  word-break: break-all;
}

.store-dashboard-block .store-information .store-items h6 {
  font-size: 16px;
  margin: 0;
}

.store-dashboard-block .store-information .store-litemist .order-rows {
  flex-wrap: nowrap;
  padding-bottom: 20px;
  overflow: hidden !important;
}

.store-dashboard-block .store-information .store-litemist .order-rows:hover {
  overflow-x: auto !important;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows::-webkit-scrollbar:vertical {
  display: none;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows
  .ant-col-4 {
  flex: 0 0 20%;
  max-width: 20%;
}

.store-dashboard-block .store-information.store-topinfo {
}

.store-dashboard-block .store-information.store-topinfo .store-container {
  background: #f0f2f5;
  padding: 6px 6px;
  box-shadow: 0px 0px 18px 2px rgb(2 21 41 / 12%);
  border-radius: 6px;
  min-height: 380px;
  height: 296px !important;
  overflow: hidden;
}
.store-dashboard-block .store-information.store-topinfo .store-container:hover {
  overflow: auto;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container::-webkit-scrollbar {
  width: 4px;
}
.store-dashboard-block
  .store-information.store-topinfo
  .store-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.store-dashboard-block
  .store-information.store-topinfo
  .store-container::-webkit-scrollbar-thumb {
  background: #888;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .store-items {
  margin-bottom: 10px;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .store-items:last-child {
  margin: 0;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .store-action {
  font-size: 20px;
  margin: 0 0 8px;
  background: white;
  padding: 12px 18px;
  border-radius: 6px;
  box-shadow: 2px 2px 14px 1px rgb(0 0 0 / 5%);
  height: 70px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .newBg {
  background: #c3da90;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .store-action.acceptBg {
  background: #ecda89;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .rejectBg {
  background: #ef8a8d;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .acceptBg1 {
  background: #dec172;
}

.store-dashboard-block .store-information.store-topinfo .rejectBg1 {
  background: #a296f4;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .rejectBg1 {
  background: #f5af84;
}

.store-dashboard-block
  .store-information.store-topinfo
  .store-container
  .holdBg {
  background: #a296f4;
}

.store-dashboard-block .store-order-informations {
  width: 100%;
}

.store-dashboard-block .store-order-informations .order-sortinfo {
  box-shadow: 0px 0px 22px 0px rgb(2 21 41 / 2%);
  border-radius: 6px;
  margin-bottom: 20px;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-top-block {
  margin: 0 0 8px;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-top-block
  .ordres-title {
  font-size: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-top-block
  .ordres-title
  .icon {
  margin-right: 5px;
  font-size: 20px;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .btn-submit {
  font-size: 16px;
  height: auto;
  border-radius: 4px;
  outline: none !important;
  box-shadow: none !important;
  /* color: rgba(0, 0, 0, 0.85); */
  font-weight: 400;
  border-radius: 20px;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .btn-submit.btn-new {
  background: #c3da90;
  border-color: #c3da90;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-bottom-block {
  margin: 20px 0 0;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-bottom-block
  .ordre--info {
  width: 100%;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-bottom-block
  .ordre--info
  h4 {
  font-size: 16px;
  color: gray;
  margin: 0;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-bottom-block
  .ordre--info
  p {
  font-size: 16px;
  margin: 0;
}
.deleivery-add-block {
  margin-top: 20px;
  padding: 5px;
}
.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-bottom-block
  .order-action {
  text-align: right;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-title-block
  .order-bottom-block
  .order-action
  .btn-submit1 {
  margin-left: 10px;
}

.store-dashboard-block
  .store-order-informations
  .order-sortinfo
  .order-listinfo-block {
  width: 100%;
}

.store-dashboard-block
  .store-order-informations
  .order-listinfo-block
  .order-tablelist-block {
  box-shadow: 0px 0px 22px 0px rgb(2 21 41 / 2%);
  border-radius: 6px;
  margin-bottom: 20px;
}

.store-dashboard-block
  .store-order-informations
  .order-listinfo-block
  .order-tablelist-block
  table
  th {
  background: transparent;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  color: gray;
}

.store-dashboard-block
  .store-order-informations
  .order-listinfo-block
  .order-tablelist-block
  table
  td {
  font-size: 14px;
  color: gray;
  color: rgba(0, 0, 0, 0.85);
}

.inputTpye {
  border: transparent;
  border-radius: 20px;
}

.SearchPlacement {
  position: absolute;
  right: 3%;
}

.partner-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

.layout-order-id {
  font-size: 25px;
  padding-left: 10px;
}

.ordre-actoions {
  font-size: 20px;
}

.partner-img2 {
  height: 40px;
  width: 40px;
  /* border-radius: 50%; */
  float: left;
  margin-right: 5px;
}

.a-btn {
  display: block;
  width: 115px;
  height: 42px;
  padding: 7px;
  text-align: center;
  border-radius: 5px;
  color: #020202;
  font-weight: bold;
  line-height: 25px;
  border: 1px solid black;
}
.KTexport {
  position: absolute;
  right: 4%;
}

.claerFilter {
  outline: 0;
}
.claerFilter {
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 35px !important;
  outline: none !important;
  box-shadow: none !important;
  background: white !important;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85) !important;
  border-color: #d9d9d9 !important;
  zoom: unset !important;
  touch-action: unset !important;
}
.mr-2 {
  margin-right: 0.625rem;
}

.is_filteralignmnet {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.svgOrder {
  height: 20px;
}

.table-row-light {
  opacity: 0.3;
}
.custome-date-control {
  width: 100%;
}
.custome-date-control .ant-picker-range {
  width: 100%;
}
.custome-date-control .ant-picker-range {
  border: 0 !important;
  background: transparent !important;
  padding: 0 !important;
}
.custome-date-control .ant-picker-range .ant-picker-input {
  border: 1px solid #000;
  opacity: 0.8;
  height: 34px;
  border-radius: 20px;
  padding: 10px 20px;
  color: #000;
}
.custome-date-control .ant-picker-range .ant-picker-input input {
  width: 100%;
  height: 100%;
  color: #000 !important;
  font-size: 16px;
}
.custome-date-control .ant-picker-range .ant-picker-range-separator {
  display: block;
}
.custome-date-control
  .ant-picker-range
  .ant-picker-range-separator
  .ant-picker-separator:before {
  content: "To";
  white-space: nowrap;
}
.custome-date-control
  .ant-picker-range
  .ant-picker-range-separator
  .ant-picker-separator {
  display: block;
}
.custome-date-control
  .ant-picker-range
  .ant-picker-range-separator
  .ant-picker-separator
  .anticon {
  display: none;
}
.custome-date-control .ant-picker-range .ant-picker-suffix {
  display: none;
}
.custome-date-control .ant-picker-range .ant-picker-input input::placeholder {
  color: #000;
}
.custome-date-control .ant-picker-range .ant-picker-active-bar {
  background: none !important;
}
.custome-date-control .ant-picker-focused {
  box-shadow: none !important;
}

.date-picker-placement {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.individual-order {
  border: 1px solid;
  border-radius: 5px;
  font-weight: bold;
  display: block;
  margin-right: 25px;
  padding: 6px 12px;
  text-align: center;
  text-transform: capitalize;
}

.list_orders_details {
  width: 100%;
  display: flex;
}

.list_orders_details .list_left_details {
  width: 75%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.list_orders_details .list_right_details {
  width: 25%;
}

.list_orders_details .item-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  padding-right: 10px;
  margin: 0 0 6px;
}

.list_orders_details .item-text .texts1 {
  font-size: 16px;
  font-weight: bold;
  padding-right: 3px;
}

.list_orders_details .item-text .texts2 {
  font-size: 14px;
  font-weight: 500;
}

.list_orders_details .list_right_details .item-text {
  width: 100%;
}

.RejectedStatus {
  background: rgb(255 17 0 / 58%);
  border-color: rgb(255 17 0 / 58%);
}
.PartiallyRejected {
  background: rgb(241, 133, 31);
  border-color: rgb(241, 133, 31);
}
.OrderFulfilledstatus {
  background: rgb(0 255 11 / 88%);
  border-color: rgb(139 195 74 / 71%);
}
.AcceptedStatus {
  background: rgb(4, 253, 128);
  border-color: rgb(4, 253, 128);
}
.partiallyAcceptedStatus {
  background: rgb(238, 255, 0);
  border-color: rgb(238, 255, 0);
}
.newOrderStatus {
  background: rgb(139 195 74 / 63%);
  border-color: rgb(139 195 74 / 63%);
}
.onHoldStatus {
  background: rgb(7, 196, 253);
  border-color: rgb(7, 196, 253);
}
.imgbox {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  margin-right: 10px;
}

.store-dashboard-block .store-information .store-litemist.store-litemist_cols {
}

.store-dashboard-block
  .store-information
  .store-litemist.store-litemist_cols
  .store_column {
  display: block;
  flex: 0 0 20%;
  max-width: 20%;
}

.store-dashboard-block
  .store-information
  .store-litemist.store-litemist_cols
  .ant-col-4 {
  display: block;
  flex: 0 0 20%;
  max-width: 20%;
}
.select-width {
  min-width: 200px;
}

.filter-icon-block {
  font-size: 16px;
  display: inline-block;
  margin-right: 5px;
}
.filter-block {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.filter-btn-block {
  display: inline-flex;
}

.filter-button {
  margin-top: 10px;
  width: 70px !important;
  height: 23px !important;
  font-size: 14px;
  border: solid 1px #000;
  align-items: center;
  display: flex;
  justify-content: center;
}
.filter-button:hover {
  margin-top: 10px;
  width: 70px !important;
  height: 23px !important;
  font-size: 14px;
  border: solid 1px #000;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #000;
  cursor: pointer;
}
/* @media (min-width: 850px) and (max-width: 1250px) { */
/* @media only screen and (max-width: 1500px) {
  .item-order-list-row {
    display: flex;
    flex-wrap: wrap;
  }
  .item-order-list-col {
    min-width: 280px;
  }
} */

@media only screen and (max-width: 1500px) {
  .item-order-list-row {
    display: flex;
    flex-wrap: wrap;
  }
  .item-order-list-col {
    min-width: 300px;
  }
  .search-box {
    min-width: 250px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 849px) {
  .n-order-col {
    min-width: 280px;
    text-align: left !important;
  }
  .n-order-row {
    display: flex;
    flex-wrap: wrap;
  }
  .select-width {
    min-width: 100px;
  }
  .item-order-list-row {
    display: flex;
    flex-wrap: wrap;
  }
  .item-order-list-col {
    min-width: 300px;
  }
  .store-order-list-row {
    display: flex;
    flex-wrap: wrap;
  }
  .store-order-list-col {
    min-width: 320px;
  }
  .topElement-row {
    display: flex;
    flex-wrap: wrap;
  }
  .topElement-col {
    min-width: 320px;
  }

  .responsvie {
    min-width: 320px;
  }
  .store-order-list-col .store-items:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    text-transform: capitalize;
    border-radius: 20px;
  }
  .order-bottom-block {
    display: flex;
    flex-wrap: wrap;
  }
  .order-bottom-block-row {
    min-width: 85px;
  }
  .mainContentRow {
    display: flex;
    flex-wrap: wrap;
  }
  .mainContentCol {
    min-width: 330px;
  }

  .order-row {
    display: flex;
    flex-wrap: wrap;
  }
  .order-col {
    min-width: 320px;
  }
  .order-details-col {
    min-width: 220px;
  }
  .order-details-items-col {
    min-width: 150px;
  }
  .list_orders_details .item-text .texts1 {
    font-size: 11px;
    font-weight: bold;
    padding-right: 3px;
  }

  .list_orders_details .item-text .texts2 {
    font-size: 11px;
    font-weight: bold;
  }

  .filter-block {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 15px;
  }

  .store-dashboard-block .store-information .store-items h5 {
    word-break: normal;
  }

  .store-dashboard-block
    .store-information
    .store-litemist
    .order-rows
    .ant-col-4 {
    flex: unset;
    max-width: 100%;
  }
  .store-dashboard-block .store-information .store-items h6 {
    display: flex;
  }
}
.store-order-list-col .store-items:hover {
  transform: translateY(-5px);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.orderBlock .ant-table-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.orderBlock .ant-table-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

.orderBlock .ant-table-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

@media only screen and (max-width: 767px) {
  .SearchPlacement {
    position: relative;
    right: 0;
    margin: 0 0 10px;
    width: 100%;
  }
  .SearchPlacement {
    width: 100%;
  }

  .SearchPlacement .ant-space {
    width: 100%;
    display: block;
  }

  .SearchPlacement .ant-space .ant-input.inputTpye {
    height: 48px;
    font-size: 16px;
  }
}
