.Reports-main {
  background: #edeef4;
  font-family: "Roboto", sans-serif;
}
.Reports-main .content-container {
  padding: 25px 40px;
}
.Reports-main .header {
  margin-bottom: 30px;
}
.Reports-main .title {
  /* font-family: Heebo; */
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
}
.Reports-main .search-box {
  height: 40px;
}
.Reports-main .content-container .CardRowSection {
  margin: 0 -20px;
}
.Reports-main .content-container .CardRowSection:after {
  content: "";
  display: table;
  clear: both;
}
.Reports-main .content-container .CardRowSection .cardColSection {
  width: calc(20% - 20px);
  padding: 0 10px;
  margin: 0px 10px 20px 10px;
}
.Reports-main .content-container .cardSection {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  padding: 25px;
  /* text-align: center; */
  background-color: #fff;
  height: 100%;
  border-radius: 14px !important;
}
.Reports-main .content-container .cardSection .ant-card-body {
  padding: 0px;
}
.Reports-main .content-container .cardSection .ant-card-body p.data {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}
.Reports-main .content-container .cardSection .ant-card-body .circle {
  width: 45px;
  height: 45px;
  background-color: #ccc;
  border-radius: 50%;
  margin-bottom: 20px;
}

.ReportList-main .modal-role-text {
  width: 250px;
  margin-right: 10px;
  height: 40px;
}

.ReportList-main .modal-role-text .ant-select-selector {
  height: 40px;
}

.ReportList-main
  .modal-role-text
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 40px;
}

.ReportList-main .modal-role-select {
  width: 150px;
  margin-right: 10px;
  height: 40px;
}
.ReportList-main .modal-role-select .ant-select-selector {
  height: 40px;
  border: 0px;
}

.ReportList-main
  .modal-role-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 30px;
  text-align: left;
}
.ReportList-main
  .modal-role-select
  .ant-select-selector
  .ant-select-selection-item {
  text-align: left;
}

.display-flex {
  /* display: flex; */
  /* align-items: center; */
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
}

/* @media screen and (max-width: 600px) {
  .Reports-main .content-container .cardSection {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
} */
@media only screen and (max-width: 1200px) {
  .report-list-row {
    display: flex;
    flex-wrap: wrap;
  }
  .report-list-col {
    min-width: 330px;
  }
  .report-col-btn {
    min-width: 250px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .report-list-col-search {
    min-width: 250px;
    margin-bottom: 10px;
  }
  .display-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
/* @media only screen and (max-width: 1200px) { */
/* @media (min-width: 1200px) and (max-width: 1900px) {
  .report-list-row {
    display: flex;
    flex-wrap: wrap;
  }
  .report-list-col {
    min-width: 230px;
  }
  .report-col-btn {
    min-width: 150px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .report-list-col-search {
    min-width: 150px;
    margin-bottom: 10px;
  }
  .display-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
} */
