.StoreTimingsSection {
  padding: 30px;
  font-family: "Roboto", sans-serif;
}
.StoreTimingsSection .StoreTimingsHeaderSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
}
.StoreTimingsSection .StoreTimingsHeaderSection h1 {
  color: #000;
  font-weight: 600;
  font-size: 38px;
  margin: 0px;
}
.StoreTimingsSection .StoreTimingsHeaderSection .StoreTimingsActions {
  display: flex;
}

.StoreTimingsSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > .searchBox {
  width: 250px;
  margin-right: 15px;
}
.StoreTimingsSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > .searchBox
  input {
  font-size: 16px;
  color: #000;
  width: 100%;
  height: 100%;
}
.StoreTimingsSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > .searchBox
  input::placeholder {
  color: #000;
  font-size: 14px;
}
.StoreTimingsSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > button.editStoreBtn {
  padding: 10px 15px;
  background-color: black;

  color: #fff;
  font-size: 16px;
  height: auto;
}

.ant-modal-body .AddInventorySection {
  align-items: center;
}

.AddStoreTimingsSection .content-add-btn {
  background: #000;
  color: #fff;
  height: 48px;
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 5px 0px;
  left: 10px;
}

.modal-role-text {
  height: 100%;
  height: 48px;
  width: 100%;
  background: #edeef4;
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
}

.StoreTimingsSection .storeTimingTableBlock table thead tr th {
  color: rgba(0, 0, 0, 0.6);
}
.StoreTimingsSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .closTimingBox
  span.icon {
  margin-right: 10px;
  display: inline-flex;
}
.StoreTimingsSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .closTimingBox
  span {
  display: inline-block;
}
.StoreTimingsSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .closTimingBox
  span
  svg {
  width: 18px;
  height: auto;
}
.StoreTimingsSection .storeTimingTableBlock table tbody tr td .editIconBox svg {
  width: 18px;
  height: auto;
  margin-right:10px
}
.StoreTimingsSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .editIconBox
  .ant-space {
  gap: 0px !important;
  justify-content: flex-start;
}
.StoreTimingsSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .editIconBox
  .ant-space-item
  .icon {
  display: inline-flex;
  margin-right: 10px;
}
/********************************ant-modal editMultipleTimeModal css *********************/
.AddStoreTimingsSection {
    padding: 0;
}
.ant-modal.editMultipleTimeModal {
  width: 100% !important;
  max-width: calc(550px - 30px);
  margin: 15px;
  max-height: calc(100% - 30px);
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.editMultipleTimeModal .ant-modal-body {
  padding: 24px 50px 24px 50px;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-heading {
  font-weight: 600;
  color: #000;
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-subtext {
  font-family: "Roboto", sans-serif !important;
}

.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .allstoreSelectBox
  .ant-col {
  width: 100%;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .allstoreSelectBox
  .ant-select {
  height: auto;
  padding-left: 0px;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .allstoreSelectBox
  .ant-select-selector {
  padding: 8px 35px 8px 15px;
  background-color: #edeef4;
  height: auto;
}

.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndTimePicker {
  margin-bottom: 20px;
}

.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndTimePicker
  .ant-col {
  width: 100%;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndTimePicker
  .ant-picker-range {
  width: 100%;
  border: none;
  padding: 0px;
  justify-content: space-between;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndTimePicker
  .ant-picker-input {
  padding: 8px 15px;
  border: 1px solid #d9d9d9;
  width: calc(50% - 15px);
  background-color: #edeef4;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndTimePicker
  .ant-picker-range-separator {
  display: none;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndTimePicker
  .ant-picker-suffix {
  display: none;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndDatePicker {
  margin-bottom: 20px;
}

.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndDatePicker
  .ant-col {
  width: 100%;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndDatePicker
  .ant-picker-range {
  width: 100%;
  border: none;
  padding: 0px;
  justify-content: space-between;
}

.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndDatePicker
  .ant-picker-input {
  padding: 8px 15px;
  border: 1px solid #d9d9d9;
  width: calc(50% - 15px);
  background-color: #edeef4;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndDatePicker
  .ant-picker-range-separator {
  display: none;
}

.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .startEndDatePicker
  .ant-picker-suffix {
  display: none;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .chooseDayFlexBox {
  margin-bottom: 10px;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .chooseDayFlexBox
  .title {
  justify-content: center;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  font-size: 16px;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .chooseDayFlexBox
  .flexBox
  .ant-checkbox-wrapper {
  margin-right: 15px;
  margin-bottom: 15px;
  width: calc(33.3% - 15px);
  margin-left: 0px;
}

.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .repeatDayFlexBox {
  margin-bottom: 10px;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .repeatDayFlexBox
  .title
  h2 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .repeatDayFlexBox
  .flexBox
  .ant-radio-group
  .ant-radio-button-wrapper {
  margin-right: 15px;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  color: #666;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .saveBtnBox
  .ant-form-item {
  margin-bottom: 0px;
}
.ant-modal.editMultipleTimeModal
  .ant-modal-body
  .AddStoreTimingsSection
  form
  button.saveBtn {
  left: 0px;
  min-width: 150px;
}
/*********************************************************************************************/

/***********************************closeTimingModal css *****************************/
.ant-modal.closeTimingModal {
  width: 100% !important;
  max-width: calc(550px - 30px);
  margin: 15px;
  max-height: calc(100% - 30px);
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.closeTimingModal .ant-modal-body {
  padding: 24px 50px 24px 50px;
}
.ant-modal.closeTimingModal .ant-modal-body .modal-heading {
  font-weight: 600;
  color: #000;
  font-family: "Roboto", sans-serif !important;
}

.ant-modal.closeTimingModal .ant-modal-body .modal-subtext {
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.closeTimingModal .ant-modal-body .dataBlock {
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.ant-modal.closeTimingModal .ant-modal-body .dataBlock > p {
  width: 100%;
}
.ant-modal.closeTimingModal .ant-modal-body .editTimingBlock {
  margin-bottom: 20px;
  justify-content: flex-end;
}
.ant-modal.closeTimingModal .ant-modal-body .editTimingBlock > p {
  display: inline-flex;
  margin-left: auto;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox {
  margin-bottom: 10px;
  padding: 5px;
  background-color: #edeef4;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox .timePicker {
  width: 50%;
  padding-right: 10px;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox .selectBox {
  width: 50%;
  padding-left: 10px;
  border-left: 1px solid #ccc;
}
.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .selectBox
  .ant-select {
  width: 100%;
  border: none;
}
.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .selectBox
  .ant-select-selector {
  border: none;
  background-color: transparent;
}

.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .timePicker
  .ant-picker {
  border: none;
  background-color: transparent;
}

.cltime-model .ant-modal-body {
  padding: 20px 22px !important;
}

.cltime-model .ant-modal-body {
  padding: 14px 14px;
}

.cltime-model .ant-modal-body .RowsStoreTimingsSection {
  width: 100%;
}

.cltime-model .ant-modal-body .RowsStoreTimingsSection .dataBlock {
  border: 1px solid #dadadd;
  width: 100%;
  padding: 10px 14px;
}

.cltime-model .ant-modal-body .RowsStoreTimingsSection .dataBlock p {
  display: block;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.cltime-model
  .ant-modal-body
  .RowsStoreTimingsSection
  .dataBlock
  p
  b:first-child {
  display: block;
  font-size: 16px;
}

.cltime-model .ant-modal-body .ant-form {
  width: 100%;
  height: 360px;
  overflow: auto;
}

.cltime-model .ant-modal-body .ant-form .timeDaypickerFlexBox {
  padding: 10px 12px;
}

.cltime-model .ant-modal-body .ant-form .timeDaypickerFlexBox .ant-form-item {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.cltime-model .ant-modal-body .ant-form .editTimingBlock span {
  display: flex;
  margin-right: 6px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
}

.cltime-model .ant-modal-body .ant-form .editTimingBlock span .anticon {
  background: #edeef4;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notcardmodal .notcardlist {
  width: 100%;
  overflow: auto;
  padding-right: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  display: block;
  max-height: 360px;
}

.notcardmodal .ant-modal-body {
  padding: 28px 26px !important;
}

.secustome-card {
  min-height: 240px;
}

.secustome-card .ant-card-body {
}

.secustome-card .ant-card-body .ant-table-wrapper {
}

.secustome-card .ant-card-body .ant-table-wrapper .ant-table-content {
  height: 185px;
  overflow: auto;
}

.secustome-card .ant-card-body .ant-table-wrapper .ant-table-content table {
  width: 100%;
  table-layout: fixed !important;
}

.secustome-card .ant-card-body .ant-table-wrapper .ant-table-content table th {
  position: sticky !important;
}

/**********************************************************************************/

/*--------------------------------- dashboard  ---------------------------------------------*/
/*--------------------------------- dashboard  ---------------------------------------------*/

.se-dashboard-block {
  display: block;
}

.se-dashboard-block .se-card {
  display: block;
  margin: 0 0 10px;
  border-radius: 10px;
}

.se-dashboard-block .se-card.se-card-total {
  margin: 0 0 16px;
}

.se-dashboard-block .se-card .ant-card-body {
  padding: 12px;
}

.se-dashboard-block .se-card.se-card-total .ant-card-body {
  display: block;
}

.se-dashboard-block .se-card.se-card-total .ant-card-body p {
  display: block;
  margin: 10px 0 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.se-dashboard-block .se-card.se-card-total .ant-card-body h3 {
  font-size: 22px;
  margin: 0 0 0;
}

.se-dashboard-block .se-card .ant-card-body .se-title {
  text-align: left;
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.se-dashboard-block .se-card .ant-card-body .se-viewall {
  display: block;
  text-align: right;
  margin: 0 0 10px;
  color: gray;
}

.se-dashboard-block .se-card .ant-table-wrapper {
  width: 100%;
}

.se-dashboard-block .se-card table {
  width: 100%;
  border-radius: 0;
  table-layout: fixed;
  overflow: auto;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

.store-dashboard-block
  .store-information
  .store-litemist
  .order-rows::-webkit-scrollbar:vertical {
  display: none;
}

.se-dashboard-block .se-card table thead th {
  background: transparent;
  color: gray;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  border: 0;
  position: sticky;
}

.se-dashboard-block .se-card table td {
  font-size: 14px;
}

.se-dashboard-block .se-card.se-cardorder {
  display: block;
  border-radius: 10px;
}

.se-dashboard-block .se-card.se-cardorder .ant-table-content {
  max-height: 470px;
  overflow: hidden;
  min-height: 470px;
}

.se-dashboard-block .se-card.se-cardorder .ant-table-content:hover {
  overflow: auto;
}

.se-dashboard-block
  .se-card.se-cardorder
  .ant-table-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.se-dashboard-block
  .se-card.se-cardorder
  .ant-table-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.se-dashboard-block
  .se-card.se-cardorder
  .ant-table-content::-webkit-scrollbar-thumb {
  background: #888;
}

.se-dashboard-block .se-card.se-cardfulfilment {
  display: block;
  border-radius: 10px;

}

.se-dashboard-block .se-card.se-cardfulfilment .ant-table-content {
  min-height: 330px;
  /* max-height: 350px; */
  overflow: hidden;
}

.se-dashboard-block .se-card.se-cardfulfilment .ant-table-content:hover {
  overflow: auto;
}
.se-dashboard-block
  .se-card.se-cardfulfilment
  .ant-table-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.se-dashboard-block
  .se-card.se-cardfulfilment
  .ant-table-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

.se-dashboard-block
  .se-card.se-cardfulfilment
  .ant-table-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

.se-dashboard-block
  .se-card.se-cardfulfilment
  .ant-table-content::-webkit-scrollbar:vertical {
  display: none;
}

.se-dashboard-block .se-card.se-cartotal {
  display: block;
  border-radius: 10px;

}
.se-dashboard-block .se-card table .ant-empty.ant-empty-normal {
  padding: 46px 0;
}

.se-dashboard-block .se-card.se-cartotal .ant-table-content {
  min-height: 330px;
  /* max-height: 350px; */
  overflow: hidden;
}
.se-dashboard-block .se-card.se-cartotal .ant-table-content .ant-empty {
  padding: 52px 0;
}

.se-dashboard-block .se-card.se-cartotal .ant-table-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.se-dashboard-block
  .se-card
  .se-cardfulfilment
  .ant-table-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.se-dashboard-block
  .se-card.se-cardfulfilment
  .ant-table-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.se-dashboard-block
  .se-card.se-cardfulfilment
  .ant-table-content::-webkit-scrollbar-thumb {
  background: #888;
}
.se-dashboard-block .se-card.se-cartotal .ant-table-content:hover {
  overflow: auto;
}

.se-dashboard-block .se-card s.se-cardfulfilment .ant-table-content:hover {
  overflow: auto;
}
.se-dashboard-block
  .se-card.se-cartotal
  .ant-table-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.se-dashboard-block
  .se-card.se-cartotal
  .ant-table-content::-webkit-scrollbar-thumb {
  background: #888;
}

.se-dashboard-block .se-card.se-cartotal .ant-table-content table {
  width: 100%;
}

.se-dashboard-block .se-card.se-cartotal .ant-table-content table td {
  padding: 2px 4px;
}

.se-dashboard-block .se-card table thead,
.se-dashboard-block .se-card table tbody tr {
  table-layout: fixed;
}

.sev_item_description {
  padding: 10px 10px;
  /* border-radius: 12px; */
  margin: 0 0 20px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
}
.sev_item_description .label-text {
  font-size: 18px;
  text-transform: capitalize;
  display: block;
  margin: 0;
  line-height: 27px;
}
.sev_item_description .label-text1 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: bold;
  display: block;
  margin: 0;
  line-height: 27px;
}

.ant-radio-group .ant-radio-group-outline .checkbox-flex {
  display: flex !important;
}

.repeats-row {
  min-width: 350px !important;
}
/*--------------------------------- FLex  ---------------------------------------------*/
@media only screen and (min-device-width: 100px) and (max-device-width: 600px) {
  .store-timings-heading {
    display: flex;
    flex-wrap: wrap;
  }
  .store-timings-search {
    min-width: 80px !important;
  }
  .store-timings-row {
    display: flex;
    flex-wrap: wrap;
  }
  .repeats-row {
    display: flex;
    flex-wrap: wrap;
    min-width: 220px !important;
  }
  .repeats-col {
    min-width: 330px;
  }
  .store-timings-col {
    margin-top: 20px;
    min-width: 80px;
    height: 40px !important;
  }
  .se-dashboard-block .se-card.se-card-total {
    min-height: 150px;
  }
}
/*--------------------------------- FLex  ---------------------------------------------*/
