.StoreSection {
  padding: 30px;
  font-family: "Roboto", sans-serif;
}
.StoreSection .StoreTimingsHeaderSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
}
.StoreSection .StoreTimingsHeaderSection h1 {
  color: #000;
  font-weight: 600;
  font-size: 28px;
  margin: 0px;
}
.StoreSection .StoreTimingsHeaderSection .StoreTimingsActions {
  display: flex;
}

.StoreSection .StoreTimingsHeaderSection .StoreTimingsActions > .searchBox {
  width: 250px;
  margin-right: 15px;
}
.StoreSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > .searchBox
  input {
  font-size: 16px;
  color: #000;
  width: 100%;
  height: 100%;
}
.StoreSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > .searchBox
  input::placeholder {
  color: #000;
  font-size: 14px;
}
.StoreSection .StoreTimingsHeaderSection .StoreTimingsActions > button.Btn {
  padding: 10px 15px;
  background-color: black;
  margin-left: 15px;
  color: #fff;
  font-size: 16px;
  height: auto;
}
.StoreSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > button.Btn.addstoreBtn {
  min-width: 150px;
}
.ant-modal-body .AddInventorySection {
  align-items: center;
}

.AddStoreTimingsSection .content-add-btn {
  background: #000;
  color: #fff;
  height: 48px;
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 5px 0px;
  left: 10px;
}

.modal-role-text {
  height: 100%;
  height: 48px;
  width: 100%;
  background: #edeef4;
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
}

.catalouge-image-block {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-top: 5px;
  margin-right: 10px;
}
.StoreSection .storeTimingTableBlock table thead tr th {
  color: rgba(0, 0, 0, 0.6);
}
.StoreSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .closTimingBox
  span.icon {
  margin-right: 10px;
  display: inline-flex;
}
.StoreSection .storeTimingTableBlock table tbody tr td .closTimingBox span {
  display: inline-block;
}
.StoreSection .storeTimingTableBlock table tbody tr td .closTimingBox span svg {
  width: 18px;
  height: auto;
}
.StoreSection .storeTimingTableBlock table tbody tr td .editIconBox svg {
  width: 18px;
  height: auto;
}
.StoreSection .storeTimingTableBlock table tbody tr td .editIconBox .ant-space {
  gap: 0px !important;
  justify-content: flex-start;
}
.StoreSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .editIconBox
  .ant-space-item
  .icon {
  display: inline-flex;
  margin-right: 10px;
}
/********************************ant-modal editMultipleTimeModal css *********************/

.ant-modal.addstoreModalBox {
  width: 100% !important;
  max-width: calc(650px - 30px);
  margin: 15px;
  max-height: calc(100% - 30px);
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addstoreModalBox .ant-modal-body {
  padding: 20px 50px 20px 50px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-heading {
  font-weight: 600;
  color: #000;
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addstoreModalBox .ant-modal-body .AddStoreTimingsSection .header {
  margin-bottom: 20px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-subtext {
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock {
  margin-bottom: 20px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox {
  width: 90px;
  height: 90px;
  min-width: 90px;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: relative;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox
  img {
  width: 90px;
  height: 90px;
  min-width: 90px;
  border-radius: 50%;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox
  .edit_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
  transform: translate(20%, -10%);
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="text"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="password"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="number"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox {
  margin-bottom: 20px;
  justify-content: space-between;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-col-6 {
  max-width: calc(50% - 10px);
  flex: 0 0 calc(50% - 10px);
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-col {
  width: 100%;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-select {
  height: auto;
  padding-left: 0px;
  max-height: 44px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .selectdropBox {
  margin-bottom: 0px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .selectdropBox
  .ant-form-item {
  margin-bottom: 0px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-select-selector {
  padding: 8px 35px 8px 15px;
  background-color: #edeef4;
  height: auto;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .saveBtnBox
  .ant-form-item {
  margin-bottom: 0px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  button.saveBtn {
  left: 0px;
  min-width: 150px;
}

/*********************************************************************************************/

/***********************************closeTimingModal css *****************************/
.ant-modal.closeTimingModal {
  width: 100% !important;
  max-width: calc(550px - 30px);
  margin: 15px;
  max-height: calc(100% - 30px);
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.closeTimingModal .ant-modal-body {
  padding: 24px 50px 24px 50px;
}
.ant-modal.closeTimingModal .ant-modal-body .modal-heading {
  font-weight: 600;
  color: #000;
  font-family: "Roboto", sans-serif !important;
}

.ant-modal.closeTimingModal .ant-modal-body .modal-subtext {
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.closeTimingModal .ant-modal-body .dataBlock {
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.ant-modal.closeTimingModal .ant-modal-body .dataBlock > p {
  width: 100%;
}
.ant-modal.closeTimingModal .ant-modal-body .editTimingBlock {
  margin-bottom: 20px;
  justify-content: flex-end;
}
.ant-modal.closeTimingModal .ant-modal-body .editTimingBlock > p {
  display: inline-flex;
  margin-left: auto;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox {
  margin-bottom: 10px;
  padding: 5px;
  background-color: #edeef4;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox .timePicker {
  width: 50%;
  padding-right: 10px;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox .selectBox {
  width: 50%;
  padding-left: 10px;
  border-left: 1px solid #ccc;
}
.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .selectBox
  .ant-select {
  width: 100%;
  border: none;
}
.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .selectBox
  .ant-select-selector {
  border: none;
  background-color: transparent;
}

.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .timePicker
  .ant-picker {
  border: none;
  background-color: transparent;
}
/**********************************************************************************/

.StoreSection .storeTabSection .ant-tabs .ant-tabs-nav {
  margin-bottom: 3px;
}
.StoreSection .storeTabSection .ant-tabs .ant-tabs-nav .ant-tabs-tab {
  min-width: 150px;
  background-color: #f6fbff;
  padding: 10px 10px;
  margin-left: 3px;
  color: #666;
}
.StoreSection
  .storeTabSection
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-tab:first-child {
  margin-left: 0px;
}
.StoreSection
  .storeTabSection
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fff;
  color: #000;
}

.StoreSection .storeTabSection .ant-tabs .ant-tabs-ink-bar {
  display: none;
}

.importBtn .ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
  color: white;
  background-color: black;
  /* height: 50px; */
  font-weight: bold;
  font-size: 15px;
}
.importBtn .ant-btn,
.ant-btn:active,
.ant-btn:hover {
  outline: 0;
  color: white;
  background-color: black;
  /* height: 50px; */
  /* font-weight: bold;
  font-size: 15px; */
}

.ant-popover-buttons button:focus {
  height: 24px;
}
.ant-popover-buttons button {
  height: 24px !important;
}

.catelogModalBox .ant-modal-content .ant-modal-close {
  position: absolute;
  top: -20px;
  right: -20px;
}

.catelogModalBox .ant-modal-content .ant-modal-body {
  width: 100%;
}

.catelogModalBox .ant-modal-content .ant-modal-body .AddStoreTimingsSection {
  width: 100%;
}

.catelogModalBox {
}

.catelogModalBox .catelog_switch {
}
.catelogModalBox {
  max-width: 860px !important;
}
.catelogModalBox .catelog_switch p {
  font-size: 12px;
  margin: 0;
  line-height: normal;
}

.catelogModalBox .catelog_switch .switch-text {
  text-align: right;
  padding-right: 10px;
}
.catalouge-main {
  background: #edeef4;
  font-family: "Roboto", sans-serif;
}
.catalouge-main .content-container {
  padding: 25px 40px;
}
.catalouge-main .header {
  margin-bottom: 30px;
}
.catalouge-main .title {
  /* font-family: Heebo; */
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
  text-align: left;
}
.catalouge-main .search-box {
  height: 40px;
}
.catalouge-main .content-container .CardRowSection {
  margin: 0 -20px;
}
.catalouge-main .content-container .CardRowSection:after {
  content: "";
  display: table;
  clear: both;
}
.catalouge-main .content-container .CardRowSection .cardColSection {
  width: calc(20% - 20px);
  padding: 0 10px;
  margin: 0px 10px 20px 10px;
}
.catalouge-main .content-container .cardSection {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 25px;
  /* text-align: center; */
  background-color: #f1f1f1;
  height: 100%;
  border-radius: 14px !important;
}
.catalouge-main .content-container .cardSection .ant-card-body {
  padding: 0px;
}
.catalouge-main .content-container .cardSection .ant-card-body p.data {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}
.catalouge-main .content-container .cardSection .ant-card-body .circle {
  width: 45px;
  height: 45px;
  background-color: #ccc;
  border-radius: 50%;
  margin-bottom: 20px;
}

/* .title {
  text-align: left;
} */
.catalouge-search {
  text-align: end;
}

/* custom search with pagination */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  /* background-color: #f1f1f1; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .item {
  color: black;
  padding: 7px 16px;
  text-decoration: none;
  display: block;
}
.search {
  padding: 15px;
}
.inv-items {
  min-width: 360px;
  min-height: 230px;
  max-height: 230px;
  overflow: auto;
  padding: 15px;
}

.dropdown .item:hover {
  background-color: #ddd;
  cursor: pointer;
}
.pagination {
  text-align: right;
  margin-right: 10px;
  padding-bottom: 10px;
}

.custom-search-loader {
  font-size: "28px";
  color: "3000";
  align-items: center;
  justify-content: center;
}
.cancel-button {
  position: relative;
  right: 0;
  text-align: right;
  margin-right: 10px;
  margin-top: 10px;
}
.cancel-button :hover {
  color: #fff;
  background-color: rgb(71, 71, 71);
}
.cancel-button-text {
  border: solid 1px;
  border-radius: 20px;
  padding: 3px;
}

/* Scrollbar css */
.AddStoreTimingsSection
  .dropdown
  .dropdown-content
  .inv-items::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AddStoreTimingsSection
  .dropdown
  .dropdown-content
  .inv-items::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
 border-radius: 10px;
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
 border-radius: 10px;
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #010101; 
}

.AddStoreTimingsSection
  .dropdown
  .dropdown-content
  .inv-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}
.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ar-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

@media only screen and (max-width: 1000px) {
  .catalouge-row {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .catalouge-col {
    min-width: 200px;
    margin-bottom: 10px;
  }
  .StoreSection
    .StoreTimingsHeaderSection
    .StoreTimingsActions
    > button.Btn.addstoreBtn {
    min-width: 300px;
  }
  .Btn {
    min-width: 300px !important;
  }
  .importBtn .ant-btn,
  .ant-btn:active,
  .ant-btn:focus {
    min-width: 300px;
    margin-left: 15px !important;
  }
  .Catalouge-main .content-container .cardSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .StoreTimingsHeaderSection .StoreTimingsActions .searchBox {
    min-width: 170px !important;
    height: 45px !important;
    margin-bottom: 10px !important;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 600px) {
  .catalouge-heading {
    display: flex;
    flex-wrap: wrap;
  }
  .catalouge-search {
    min-width: 170px;
    margin-bottom: 10px;
  }
  .catalouge-row {
    display: flex;
    flex-wrap: wrap;
  }
  .catalouge-col {
    min-width: 330px;
  }
  .catalouge-main .content-container .cardSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.Catlouge-backButton {
  cursor: "pointer";
  width: "60px";
}
.backButton:hover {
  cursor: "pointer";
  width: "60px";
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  transition: 0.5s;
  transform: translateX(-15px);
}
.catalouge-image-block:hover {
  cursor: "pointer";
  width: "60px";
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.offer-BOGO {
  height: 50px;
  /* border: 1px solid black;
  border-radius: 20px; */
  align-items: center;
}

.offerSaveBtn {
  background-color: #000;
  color: #fff;
  width: 75px !important;
  height: 35px !important;
  border-radius: 30px !important;
  margin-left: 20px !important;
  margin-top: 25px;
}

.lable-block {
  display: inline-grid;
}
