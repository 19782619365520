.notcardlist .seen b {
  font-weight: 400;
}

.notcardlist .seen {
  background: none !important;
}

.ant-modal.addstoreModalBox .ant-modal-body .AddStoreTimingsSection .header {
  margin-bottom: 0 !important;
}

.AddStoreTimingsSection {
  padding: 0 40px;
}
.notification-alert {
  width: 90%;
  margin: 5px;
}
.d-none {
  display: none !important;
}
.notification_pagination {
  margin-top: 20px !important;
  display: flex;
  justify-content: flex-end;
}
.notify-loader {
  font-size: 24px;
  color: black !important;
  /* position: absolute !important;
  top: 50% !important;
  bottom: 50% !important; */
  box-sizing: border-box !important;
}
.notification-time {
  position: absolute;
  bottom: 0px;
  right: 5px;
  font-size: 12px;
  font-weight: bold;
}
.AddStoreTimingsSection .notcardlist::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AddStoreTimingsSection .notcardlist::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}
.AddStoreTimingsSection .notcardlist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}
