.role-add-modal.modal-body {
  padding: 10px 50px;
}

.role-add-modal .header {
  margin-bottom: 30px;
}

.role-add-modal .modal-heading {
  font-family: Heebo;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.role-add-modal .modal-subtext {
  opacity: 0.5;
  font-family: Heebo;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.role-add-modal .modal-avatar {
  margin: 25px 0px;
}
.role-add-modal .content-add-btn {
  background: #000;
  color: #fff;
  border: none;
  width: 100%;
  font-size: 13px;
  padding: 5px 0px;
}
.role-add-modal .content-add-btn:hover {
  background: #000;
}
.role-add-modal .modal-role-text {
  height: 100%;
  width: 100%;
  background: #edeef4;
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
}
.role-add-modal .modal-rows {
  margin: 20px 0px;
  height: 40px;
}
.role-add-modal .modal-dropdown {
  height: 100%;
  width: 100%;
  background: #edeef4;
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
}
.role-add-modal .modal-save-btn {
  background: #000;
  color: #fff;
  border: none;
  margin-top: 30px;
  font-size: 18px;
  padding: 5px 60px;
  height: 3.5rem;
}
.role-add-modal .modal-save-btn:hover {
  background: #000;
}

.role-res-main {
  background: #edeef4;
}
.role-res-main .content-container {
  padding: 25px 40px;
}
.role-res-main .header {
  margin-bottom: 30px;
}
.no-data {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: ui-monospace;
  font-size: 21px;
  font-weight: 600;
  justify-content: center;
  margin-top: 25px;
}
.role-res-main .title {
  font-family: Heebo;
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 0;
  color: #000;
}
.role-res-main .search-box {
  height: 40px;
}
.role-res-main .add-btn {
  background: #000;
  color: #fff;
  border: none;
  width: 100%;
  font-size: 13px;
  padding: 5px 40px;
}
.permission .ant-checkbox-checked .ant-checkbox-inner {
  background: #8798ad;
  border-color: #8798ad;
}

.main-content {
  border-radius: 15px;
  margin-top: 11px;
}
.role-res-main .main-content .role-name,
.module-name {
  font-family: SFUIText;
  font-size: 17px;
}
.role-res-main .main-content .permission {
  font-family: SFUIText;
  font-size: 14px;
  font-weight: bold;
}
.role-res-main .main-content .act-status {
  font-family: SFUIText;
  font-size: 13px;
  font-weight: 500;
}
.role-res-main .main-content .status-switch.ant-switch-checked {
  background: #000;
}
.role-res-main .main-content .option-btn {
  background: #edeef4;
  color: #000;
  cursor: pointer;
}

.role-edit-modal.modal-body {
  padding: 10px 40px;
  position: relative;
}

.role-edit-modal .header {
  margin-bottom: 30px;
}

.role-edit-modal .modal-heading {
  font-family: Heebo;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.role-edit-modal .modal-subtext {
  opacity: 0.5;
  font-family: Heebo;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.role-edit-modal .modal-avatar {
  margin: 25px 0px;
}
.role-edit-modal .content-add-btn {
  background: #000;
  color: #fff;
  border: none;
  width: 100%;
  font-size: 13px;
  padding: 5px 0px;
}
.role-edit-modal .content-add-btn:hover {
  background: #000;
}
.role-edit-modal .modal-role-text {
  height: 100%;
  width: 100%;
  background: #edeef4;
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
}
.role-edit-modal .modal-rows {
  margin: 20px 0px;
  height: 40px;
}
.role-edit-modal .modal-dropdown {
  height: 100%;
  width: 100%;
  background: #edeef4;
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
}
.role-edit-modal .modal-save-btn {
  background: #000;
  color: #fff;
  border: none;
  margin-top: 30px;
  font-size: 18px;
  padding: 5px 60px;
  height: 3rem;
}
.role-edit-modal .modal-save-btn:hover {
  background: #000;
}

.addPermissionButton {
  background: #000;
  color: #fff;
  border: none;
  margin-top: 36px;
  font-size: 16px !important;
  padding: 4px 11px;
  height: 50px !important;
  position: absolute;
  right: 30%;
  top: 50px;
  z-index: 99;
}

/**************************rolePermissionSection css *****************/

.rolePermissionSection .rolePermissionTableHead {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
}
.rolePermissionSection .rolePermissionTableHead strong {
  color: #666;
  font-size: 17px !important;
  font-weight: normal;
}

.rolePermissionSection .rolePermissionTable .main-content .role-name strong {
  font-weight: normal;
}
.rolePermissionSection .rolePermissionTable .main-content .act-status {
  font-size: 17px;
}
.rolePermissionSection .rolePermissionTable .main-content .noPermission {
  text-align: center;
  font-size: 17px;
}

.pt {
  display: flex;
  align-items: center;
  align-content: center;
  width: 220px;
}
.actionBtnBox {
  margin-left: 10px;
}

.permission-block {
  max-height: 150px;
  overflow: auto;
}
@media only screen and (max-width: 800px) {
  .pt {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .flex-col {
    min-width: 330px;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
  }
  .action-col {
    min-width: 150px;
  }
  .flex-module-col {
    min-width: 250px;
  }
  .flex-module-row {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
  }

  .module-name {
    margin-bottom: 7px;
    font-weight: bold;
  }

  .role-title {
    margin-bottom: 15px;
  }
  .role-title-text {
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    right: 50%;
  }
  .actionPos {
    position: absolute;
    right: 25%;
    bottom: 50%;
  }
  .relative {
    position: relative;
  }
}

.role-edit-modal.modal-body .ant-space .ant-space-item .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

/* .role-edit-modal.modal-body
  .ant-space
  .ant-space-item
  .ant-checkbox-group
  .ant-space {
  flex-wrap: wrap;
} */

@media only screen and (max-width: 849px) {
  .role-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 50px;
  }
  .role-col {
    min-width: 280px;
  }
  .addPermissionButton {
    background: #000;
    color: #fff;
    border: none;
    margin-top: 36px;
    font-size: 16px !important;
    padding: 4px 11px;
    height: 40px !important;
    position: absolute;
    right: 10%;
    top: 10%;
    z-index: 99;
    margin-top: 90px;
  }
  .role-add-modal.modal-body {
    padding: 10px 0px !important;
  }
  .role-edit-modal.modal-body {
    padding: 10px 0px !important;
    position: relative;
  }
}
