.peoplemanageSection {
  padding: 30px;
}

.peoplemanageSection .header .searchBtnFlexBox .search-box {
  min-height: 45px;
}
.peoplemanageSection .header .searchBtnFlexBox .import-btn {
  min-height: 45px;
  background-color: #000;
  padding: 10px 20px;
  color: #fff;
  display: inline-flex;
  align-items: center;
}
.peoplemanageSection .header .searchBtnFlexBox .export-btn {
  outline: 0;
  color: white;
  background-color: black;
  height: 50px;
  font-weight: bold;
  font-size: 15px;
  align-items: center;
}

.peoplemanageSection .header .searchBtnFlexBox .export-btn a {
  /* outline: 0; */
  color: white;
}

.peoplemanageSection .header .searchBtnFlexBox .add-btn {
  min-height: 45px;
  background-color: #000;
  padding: 10px 40px;
  color: #fff;
  display: inline-flex;
  align-items: center;
}
/****************************************addEmployeeModalBox css ********************************************/

.ant-modal.addEmployeeModalBox {
  width: 100% !important;
  max-width: calc(650px - 30px);
  margin: 15px;
  max-height: calc(100% - 30px);
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addEmployeeModalBox .ant-modal-body {
  padding: 20px 50px 20px 50px;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-heading {
  font-weight: 600;
  color: #000;
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addEmployeeModalBox .ant-modal-body .AddStoreTimingsSection .header {
  margin-bottom: 20px;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-subtext {
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock {
  margin-bottom: 20px;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox {
  width: 90px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: relative;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox
  img {
  width: 100%;
  height: 100%;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox
  .edit_btn {
  position: absolute;
  top: 70%;
  left: 60%;
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
  transform: translate(20%, -10%);
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  #errorField {
  background-color: white;
  color: #ff4d4f;
  height: auto;
  outline: none;
  text-align: left;
  box-shadow: none;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="text"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="number"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox {
  margin-bottom: 0px;
  justify-content: space-between;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-col-6 {
  max-width: calc(50% - 10px);
  flex: 0 0 calc(50% - 10px);
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-col {
  width: 100%;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-select {
  height: auto;
  padding-left: 0px;
  max-height: 44px;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .selectdropBox {
  margin-bottom: 0px;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .selectdropBox
  .ant-form-item {
  margin-bottom: 0px;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-select-selector {
  padding: 8px 35px 8px 15px;
  background-color: #edeef4;
  height: auto;
}

.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .saveBtnBox
  .ant-form-item {
  margin-bottom: 0px;
}
.ant-modal.addEmployeeModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  button.saveBtn {
  left: 0px;
  min-width: 150px;
}
.StoreSection .StoreTimingsHeaderSection .StoreTimingsActions > button.Btn {
  padding: 10px 15px;
  background-color: black;
  margin-left: 15px;
  color: #fff;
  font-size: 16px;
  height: auto;
}

@media (max-width: 800px) {
  .ant-modal {
    width: auto !important;
    margin: 10px;
  }
}

@media only screen and (min-device-width: 100px) and (max-device-width: 600px) {
  .people-heading {
    display: flex;
    flex-wrap: wrap;
  }
  .people-search {
    min-width: 330px !important;
  }
  .people-title-text {
    font-size: 18px !important;
  }
  .people-r {
    min-width: 100px;
    display: flex;
    flex-wrap: wrap;
  }
  .people-c {
    /* min-width: 100px; */
  }
  .add-btn {
    width: 30px;
    justify-content: center;
    text-align: center;
  }
}

/*************************************************************************************/
