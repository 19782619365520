.LoginMainSection {
  height: 100vh;
  display: flex;
}
.LoginMainSection > div {
  width: 50%;
  height: 100%;
}
.LoginMainSection div.imgBlock {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}
.LoginMainSection .imgBlock img {
  max-width: 140px;
}

.LoginMainSection .loginfieldBox {
  padding: 50px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginMainSection .loginfieldBox .login-information {
  width: 100%;
}

/* .LoginMainSection div.loginfieldBox {
  padding: 100px;
} */
.LoginMainSection div.loginfieldBox .head {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #000;
  font-size: 30px;
  line-height: normal;
}
.LoginMainSection div.loginfieldBox .title {
  font-size: 18px;
  color: #666;
  margin: 0px;
}

.LoginMainSection div.loginfieldBox form {
  margin-top: 50px;
  max-width: 500px;
  /* margin-left: auto; */
  margin-right: auto;
}
.LoginMainSection div.loginfieldBox form > div {
  margin-bottom: 25px;
}
.LoginMainSection div.loginfieldBox form > div label {
  display: block;
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.LoginMainSection div.loginfieldBox form > div input[type="text"] {
  padding: 15px;
  color: #000;
  font-size: 16px;
  height: 50px;
  background-color: #edeef4;
  border: none;
  outline: none;
  width: 100%;
}
.LoginMainSection div.loginfieldBox form > div input[type="password"] {
  padding: 15px;
  color: #000;
  font-size: 16px;
  height: 50px;
  border: none;
  outline: none;
  background-color: #edeef4;
  width: 100%;
}
.LoginMainSection div.loginfieldBox form button.signInBtn {
  height: 50px;
  line-height: normal;
  min-width: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #000;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .LoginMainSection {
    flex-wrap: wrap;
    min-height: unset;
  }
  .LoginMainSection > div {
    width: 100%;
    height: auto;
  }

  .LoginMainSection div.imgBlock {
    padding: 30px 20px;
    padding-bottom: 120px;
  }

  .LoginMainSection .imgBlock img {
    max-width: 110px;
  }

  .LoginMainSection .loginfieldBox {
    padding: 10px 20px;
  }

  .LoginMainSection .loginfieldBox .login-information {
    background: white;
    margin-top: -90px;
    padding: 30px 30px;
    box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 10%);
    border-radius: 4px;
  }
}
