.InventoryManagementSection {
  padding: 10px;
}

.InventoryManagementSection .InventoryHeaderSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.InventoryManagementSection .InventoryHeaderSection .InventoryActions {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.InventoryManagementSection .InventoryHeaderSection .InventoryActions > div {
  width: 60%;
  margin: 10px;
}

.InventoryManagementSection .InventoryHeaderSection .InventoryActions > button {
  width: 60%;
  background-color: black;
  margin: 10px;
  color: #fff;
  height: 50px;
}
.StoreTimingsSection
  .StoreTimingsHeaderSectio
  .StoreTimingsActions
  > .searchBox
  input {
  font-size: 16px;
  color: #000;
  width: 100%;
  height: 100%;
}
.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .ant-upload
  > button {
  width: 100%;
  background-color: black;
  margin: 10px;
  color: #fff;
  height: 50px;
}

.ant-modal-body .AddInventorySection {
  align-items: center;
}

.modal-body {
  padding: 20px 50px;
}

.header {
  margin-bottom: 35px;
}

.modal-heading {
  font-family: Heebo;
  font-size: 34px;
  font-weight: 500;
}
.modal-subtext {
  opacity: 0.5;
  font-family: Heebo;
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
}

.AddInventorySection .modal-save-btn {
  background: #000;
  color: #fff;
  border: none;
  margin-top: 50px;
  font-size: 18px;
  padding: 5px 60px;
}

.AddInventorySection .content-add-btn {
  background: #000;
  color: #fff;
  height: 48px;
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 5px 0px;
  left: 10px;
}

.AddInventorySection .modal-heading {
  font-family: Heebo;
  font-size: 34px;
  font-weight: 500;
  margin: 0px;
}
.AddInventorySection .modal-subtext {
  opacity: 0.5;
  font-family: Heebo;
  font-size: 18px;
  font-weight: 500;
}

.AddInventorySection .rowCol {
  margin: 10px;
}

.AddInventorySection .modal-role-text {
  height: 100%;
  height: 48px;
  width: 100%;
  background: #edeef4;
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
}

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: none !important;
} */

.ant-modal-body
  .AddInventorySection
  .selectdropBox
  .ant-form-item-control
  .ant-select-selector {
  padding: 8px 35px 8px 15px;
  background-color: #edeef4;
  height: auto;
}

.ant-modal-body
  .AddInventorySection
  .selectdropBox
  .ant-form-item-control
  .ant-select {
  padding: 0;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .selectdropBox {
  margin-bottom: 0px;
  border: 0;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .selectdropBox
  .ant-form-item {
  margin-bottom: 0px;
}

/*******************ant-modal sev_editInventoryModal css *********/

.ant-modal.sev_editInventoryModal .ant-modal-body {
  padding: 30px 60px;
}
.ant-modal.sev_editInventoryModal .ant-modal-body .modal-heading {
  font-weight: 600;
}
.ant-modal.sev_editInventoryModal
  .ant-modal-body
  .AddInventorySection
  .ant-form
  .ant-row.modal-rows {
  margin: 0px -10px;
}
.ant-modal.sev_editInventoryModal
  .ant-modal-body
  .AddInventorySection
  .ant-form
  .ant-row
  .ant-col-12 {
  flex: 0 0 calc(50% - 20px);
  max-width: calc(50% - 20px);
}

.ant-modal.sev_editInventoryModal
  .ant-modal-body
  .AddInventorySection
  .ant-form
  .ant-row
  .ant-col-6 {
  flex: 0 0 calc(25% - 20px);
  max-width: calc(25% - 20px);
}
/**************************************************************/

/*************addInventoryModal css***************/

.ant-modal.addInventoryModal .ant-modal-body {
  padding: 30px 60px;
}
.ant-modal.addInventoryModal .ant-modal-body .modal-heading {
  font-weight: 600;
}
.ant-modal.addInventoryModal
  .ant-modal-body
  .AddInventorySection
  .ant-form
  .ant-row.modal-rows {
  margin: 0px -10px;
}
.ant-modal.addInventoryModal
  .ant-modal-body
  .AddInventorySection
  .ant-form
  .ant-row
  .ant-col-12 {
  flex: 0 0 calc(50% - 20px);
  max-width: calc(50% - 20px);
}

.ant-modal.addInventoryModal
  .ant-modal-body
  .AddInventorySection
  .ant-form
  .ant-row
  .ant-col-6 {
  flex: 0 0 calc(25% - 20px);
  max-width: calc(25% - 20px);
}

/*************************************************/
/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 45px;
  padding: 7px 11px;
} */

.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .selectdropBox
  .ant-select-selector {
  height: 44px;
  padding: 7px 11px;
  border: 0px !important;
}

.InventoryManagementSection .InventoryHeaderSection h1 {
  font-size: 38px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
}

.InventoryManagementSection .InventoryHeaderSection .InventoryActions {
  align-items: center;
}

.InventoryManagementSection .InventoryHeaderSection .InventoryActions > div {
  margin: 0;
  margin-right: 10px;
}

.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .searchBox {
  font-size: 16px;
  height: 44px;
  border: 0;
  outline: none !important;
  box-shadow: none !important;
}

.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .Inventor-btn {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  outline: none !important;
  box-shadow: none !important;
  height: 44px !important;
  padding: 4px 15px;
  margin: 0 !important;
  margin-left: 6px !important;
  width: auto;
  background-color: black;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:8px
}

.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .Inventor-btn:last-child {
  margin: 0;
}

.InventoryManagementSection .InventoryHeaderSection .InventoryActions span {
  display: block;
}

.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .ant-upload-select-text {
  display: block;
}

.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .Inventor-btn
  .ant-upload {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.InventoryManagementSection .InventoryHeaderSection .InventoryActions {
  width: auto;
}

.InventoryManagementSection .InventoryHeaderSection {
  align-items: center;
  margin: 0 0 35px;
}
.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .Inventor-btn
  a {
  color: white;
}

.InventoryManagementSection
  .InventoryHeaderSection
  .InventoryActions
  .Inventor-btn
  .anticon {
  margin-right: 5px;
}

input[type="search"]::placeholder {
  /* Firefox, Chrome, Opera */
  font-size: 13px;
}

.sev_editInventoryModal {
  max-width: 580px;
}
.sev_editInventoryModal .ant-modal-content {
  border-radius: 24px;
}
.sev_editInventoryModal .ant-modal-content .ant-modal-close {
}
.sev_editInventoryModal .ant-modal-content .ant-modal-close .ant-modal-close-x {
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-close
  .ant-modal-close-x
  .anticon.anticon-close-circle {
  background: transparent;
  font-size: 23px;
  color: #000;
}
.sev_editInventoryModal .ant-modal-content .ant-modal-body {
  padding: 30px 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.sev_editInventoryModal .ant-modal-content .ant-modal-body .header {
  width: 100%;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .header
  .modal-heading {
  font-size: 26px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .header
  .modal-subtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.sev_editInventoryModal .ant-modal-content .ant-modal-body label {
  font-size: 18px;
  font-weight: 500;
  display: block;
  width: 100%;
}
.sev_editInventoryModal .ant-modal-content .ant-modal-body label t {
}
.sev_item_description {
  background: rgba(211, 211, 211, 0.43);
  padding: 10px 10px;
  /* border-radius: 12px; */
  margin: 0 0 20px;
  width: 100%;
}
.sev_item_description .label-text {
  font-size: 18px;
  text-transform: capitalize;
  display: block;
  margin: 0;
  line-height: 27px;
}
.sev_item_description .label-text1 {
  font-size: 16px;
  text-transform: capitalize;
  display: block;
  margin: 0;
  line-height: 27px;
}
.sev_editInventoryModal .ant-modal-content .ant-modal-body .form-group {
  display: block;
  width: 100%;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .ant-form-item-control {
  width: 100%;
  display: block;
  border: 0;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control {
  width: 100%;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range {
  width: 100%;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .ant-form-item-control
  .ant-form-item-control-input-content {
  border: 0;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range {
  border: 0 !important;
  background: transparent !important;
  padding: 0 !important;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range
  .ant-picker-input {
  border: 1px solid #000;
  height: 43px;
  border-radius: 7px;
  padding: 10px 20px;
  color: #000;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range
  .ant-picker-input
  input {
  width: 100%;
  height: 100%;
  color: #000 !important;
  font-size: 16px;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range
  .ant-picker-range-separator {
  display: block;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range
  .ant-picker-range-separator
  .ant-picker-separator:before {
  content: "To";
  white-space: nowrap;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range
  .ant-picker-range-separator
  .ant-picker-separator {
  display: block;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range
  .ant-picker-range-separator
  .ant-picker-separator
  .anticon {
  display: none;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range
  .ant-picker-suffix {
  display: none;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .custome-date-control
  .ant-picker-range
  .ant-picker-input
  input::placeholder {
  color: #000;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .form-group
  .ant-form-item-control-input-content {
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .ant-form-item-control
  .ant-input {
  border: 1px solid #000;
  height: 43px;
  border-radius: 7px;
  padding: 10px 20px;
  color: #000;
}
.sev_editInventoryModal
  .ant-modal-content
  .ant-modal-body
  .ant-form-item-control
  .ant-form-item-control-input {
  outline: none !important;
  box-shadow: none !important;
  border: 0;
}

.mt-2 {
  margin-top: 8px !important;
}

.un_items_block {
  border-style: solid;
  border-width: 1px;
  margin: 5px;
  padding: 10px;
  min-width: 80%;
}

.inv-item {
  line-height: 5px;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-top: 10px;
  height: 50px;
}

.sample-file {
  /* display: none; */
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  margin-top: -21px;
  font-size: 15px;
  margin-bottom: 14px;
}

.InventoryManagementSection .ant-table-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.InventoryManagementSection .ant-table-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

.InventoryManagementSection .ant-table-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

@media only screen and (max-width: 1000px) {
  .inv-col {
    min-width: 200px;
    margin-bottom: 10px;
  }
  .inv-row {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .inv-title {
    min-width: 200px;
  }
  .ant-modal.addInventoryModal .ant-modal-body {
    padding: 15px 30px;
  }
  .InventoryManagementSection
    .InventoryHeaderSection
    .InventoryActions
    .searchBox {
    width: 120px !important;
    margin-left: 10px !important;
  }
}

.red-dot{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    background-color: red;
}
.green-dot{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    background-color: rgb(23, 201, 16);
}