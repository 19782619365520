.global-layout {
  height: 100vh;
  /* height: auto; */
  width: 100vw;
}

.home-header {
  background: #fff;
  position: sticky;
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 2;
}
.home-header.alert-box,
.site-layout.alert-box,
.ant-layout-sider-dark.alert-box {
  margin-top: 42px !important;
}
/* .home .ant-layout-sider.ant-layout-sider-dark.false { */
#custom-sider {
  /* overflow: 'auto';
               height: '100vh';
                position: 'fixed'; */
  background-color: #000000 !important;
  left: 0 !important;
  padding-left: 10px !important;
  z-index: 1 !important;
  overflow: auto;
}
.single-notification {
  height: auto !important;
  border-radius: 8px;
}
.ml-5 {
  margin-left: 5px;
}

.notify-red {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #fd9c9c;
  color: #000;
  z-index: 9;
  text-align: 'center';
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 10px 0;
}

.notify-red p {
  margin: 0;
}

.notify-red p button {
  background: none;
  box-shadow: none;
  border: none;
  font-weight: 700;
  font-style: italic;
  padding: 0;
}

.ant-layout {
  padding-top: 28px;
}
.home .bell-icon {
  font-size: 13px;
}
.home-header-username {
  font-family: 'Heebo';
  font-size: 14px;
  font-weight: 500;
  color: #111215;
}
.home-nav-list {
  margin-top: 45px;
}
.home-notification {
  background-color: #000;
  font-size: 16px;
}
.home-nav-list.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: transparent;
  border: 4px #fff;
}
.home-nav-list-items {
  border-style: none solid none none;
}
.home-nav-list-items .radio-box {
  padding: 4px 12px;
  margin-right: 10px;
  border-radius: 50%;
  background: #1a1a1a;
}
.home-nav-list-item-text {
  font-family: Heebo;
  font-size: 16px;
}
.home-nav-list.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .radio-box {
  background: #fff;
}
.mb-0 {
  margin-bottom: 0px !important;
}

.site-layout {
  margin: 0 !important;
  padding-left: 200px;
}
@media only screen and (max-width: 1000px) {
  .site-layout {
    margin: 0 !important;
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .logo {
    float: left;
    width: 150px !important;
    height: 31px;
    /* margin: 16px 24px 16px 0; */
    background: rgba(255, 255, 255, 0.2);
  }
}
@media only screen and (max-width: 844px) {
  .sider-style {
    /* height: '100vh', */
    /* position: 'fixed', */
    background-color: #000;
    left: 0;
    padding-left: 10px;
    z-index: 1;
    overflow: unset;
  }

  #custom-sider {
    overflow: unset !important;
  }
}

.nnn {
  display: flex;
  justify-content: flex-end;
}
.logo {
  float: left;
  width: 250px;
  height: 51px;
  /* margin: 16px 24px 16px 0; */
  background: rgba(255, 255, 255, 0.2);
}

.ant-layout-sider {
  position: fixed !important;
  left: 0 !important;
  top: 0% !important;
  height: 100% !important;
  padding-top: 65px;
}

.linkColor {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-title-content {
  overflow: initial !important;
}
.sider-style {
  /* height: '100vh', */
  /* position: 'fixed', */
  background-color: #000;
  padding-left: 10px;
  z-index: 1;
  overflow: auto;
}
