.StoreSection {
  padding: 30px;
  font-family: "Roboto", sans-serif;
}
.StoreSection .StoreTimingsHeaderSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
}
.StoreSection .StoreTimingsHeaderSection h1 {
  color: #000;
  font-weight: 600;
  font-size: 38px !important;
  margin: 0px;
}
.StoreSection .StoreTimingsHeaderSection .StoreTimingsActions {
  display: flex;
}

.StoreSection .StoreTimingsHeaderSection .StoreTimingsActions > .searchBox {
  width: 250px;
  margin-right: 15px;
}
.StoreSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > .searchBox
  input {
  font-size: 16px;
  color: #000;
  width: 100%;
  height: 100%;
}
.StoreSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > .searchBox
  input::placeholder {
  color: #000;
  font-size: 14px;
}
.StoreSection .StoreTimingsHeaderSection .StoreTimingsActions > button.Btn {
  padding: 10px 15px;
  background-color: black;
  margin-left: 15px;
  color: #fff;
  font-size: 16px;
  height: auto;
}
.StoreSection
  .StoreTimingsHeaderSection
  .StoreTimingsActions
  > button.Btn.addstoreBtn {
  min-width: 150px;
}
.ant-modal-body .AddInventorySection {
  align-items: center;
}

.AddStoreTimingsSection .content-add-btn {
  background: #000;
  color: #fff;
  height: 48px;
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 5px 0px;
  left: 10px;
}

.modal-role-text {
  height: 100%;
  height: 48px;
  width: 100%;
  background: #edeef4;
  text-align: start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
}

.StoreSection .storeTimingTableBlock table thead tr th {
  color: rgba(0, 0, 0, 0.6);
}
.StoreSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .closTimingBox
  span.icon {
  margin-right: 10px;
  display: inline-flex;
}
.StoreSection .storeTimingTableBlock table tbody tr td .closTimingBox span {
  display: inline-block;
}
.StoreSection .storeTimingTableBlock table tbody tr td .editIconBox span .icon {
  margin-right: 10px;
  display: inline-flex;
}
.StoreSection .storeTimingTableBlock table tbody tr td .closTimingBox span svg {
  width: 18px;
  height: auto;
}
.StoreSection .storeTimingTableBlock table tbody tr td .editIconBox svg {
  width: 18px;
  height: auto;
}
.StoreSection .storeTimingTableBlock table tbody tr td .editIconBox .ant-space {
  gap: 0px !important;
  justify-content: flex-start;
}
.StoreSection
  .storeTimingTableBlock
  table
  tbody
  tr
  td
  .editIconBox
  .ant-space-item
  .icon {
  display: inline-flex;
  margin-right: 10px;
}
/********************************ant-modal editMultipleTimeModal css *********************/

.ant-modal.addstoreModalBox {
  width: 100% !important;
  max-width: calc(650px - 30px);
  margin: 15px;
  max-height: calc(100% - 30px);
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addstoreModalBox .ant-modal-body {
  padding: 20px 50px 20px 50px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-heading {
  font-weight: 600;
  color: #000;
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addstoreModalBox .ant-modal-body .AddStoreTimingsSection .header {
  margin-bottom: 20px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-subtext {
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock {
  margin-bottom: 20px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox {
  width: 90px;
  height: 90px;
  min-width: 90px;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: relative;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox
  img {
  width: 90px;
  height: 90px;
  min-width: 90px;
  border-radius: 50%;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox
  .edit_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
  transform: translate(20%, -10%);
}


button, html [type="button"],[type="text"] {
  border-radius: 8px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
}

.ant-modal-content {
    border-radius: 10px !important;
}

.StoreSection .storeTabSection .ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #fff !important;
    /* color: #000; */
    border-radius: 8px;
}
.ant-tabs .ant-tabs-nav .ant-tabs-tab {
    border-radius: 10px !important;
    background-color: rgba(0, 0,0,0.09) !important;
}
.ant-table.ant-table-small {
    font-size: 14px;
    border-radius: 8px !important;
}
.ant-table-thead{
  border-radius: 10px !important
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="text"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="number"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="password"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  input[type="number"] {
  background-color: #edeef4;
  height: auto;
  padding: 10px 15px;
  outline: none;
  box-shadow: none;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox {
  margin-bottom: 20px;
  justify-content: space-between;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-col-6 {
  max-width: calc(50% - 10px);
  flex: 0 0 calc(50% - 10px);
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-col {
  width: 100%;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-select {
  height: auto;
  padding-left: 0px;
  max-height: 44px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .selectdropBox {
  margin-bottom: 0px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .selectdropBox
  .ant-form-item {
  margin-bottom: 0px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-select-selector {
  padding: 8px 35px 8px 15px;
  background-color: #edeef4;
  height: auto;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .saveBtnBox
  .ant-form-item {
  margin-bottom: 0px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  button.saveBtn {
  left: 0px;
  min-width: 150px;
}
/*********************************************************************************************/

/***********************************closeTimingModal css *****************************/
.ant-modal.closeTimingModal {
  width: 100% !important;
  max-width: calc(550px - 30px);
  margin: 15px;
  max-height: calc(100% - 30px);
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.closeTimingModal .ant-modal-body {
  padding: 24px 50px 24px 50px;
}
.ant-modal.closeTimingModal .ant-modal-body .modal-heading {
  font-weight: 600;
  color: #000;
  font-family: "Roboto", sans-serif !important;
}

.ant-modal.closeTimingModal .ant-modal-body .modal-subtext {
  font-family: "Roboto", sans-serif !important;
}
.ant-modal.closeTimingModal .ant-modal-body .dataBlock {
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.ant-modal.closeTimingModal .ant-modal-body .dataBlock > p {
  width: 100%;
}
.ant-modal.closeTimingModal .ant-modal-body .editTimingBlock {
  margin-bottom: 20px;
  justify-content: flex-end;
}
.ant-modal.closeTimingModal .ant-modal-body .editTimingBlock > p {
  display: inline-flex;
  margin-left: auto;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox {
  margin-bottom: 10px;
  padding: 5px;
  background-color: #edeef4;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox .timePicker {
  width: 50%;
  padding-right: 10px;
}
.ant-modal.closeTimingModal .ant-modal-body .timeDaypickerFlexBox .selectBox {
  width: 50%;
  padding-left: 10px;
  border-left: 1px solid #ccc;
}
.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .selectBox
  .ant-select {
  width: 100%;
  border: none;
}
.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .selectBox
  .ant-select-selector {
  border: none;
  background-color: transparent;
}

.ant-modal.closeTimingModal
  .ant-modal-body
  .timeDaypickerFlexBox
  .timePicker
  .ant-picker {
  border: none;
  background-color: transparent;
}
/**********************************************************************************/

.StoreSection .storeTabSection .ant-tabs .ant-tabs-nav {
  margin-bottom: 3px;
}
.StoreSection .storeTabSection .ant-tabs .ant-tabs-nav .ant-tabs-tab {
  min-width: 150px;
  background-color: #f6fbff;
  padding: 10px 10px;
  margin-left: 3px;
  color: #666;
}
.StoreSection
  .storeTabSection
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-tab:first-child {
  margin-left: 0px;
}
.StoreSection
  .storeTabSection
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fff;
  color: #000;
}

.StoreSection .storeTabSection .ant-tabs .ant-tabs-ink-bar {
  display: none;
}

.importBtn .ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
  color: white;
  background-color: black;
  /* height: 50px; */
  font-weight: bold;
  font-size: 15px;
}
.importBtn .ant-btn,
.ant-btn:active,
.ant-btn {
  height: 50px;
}
.StoreSection .StoreTimingsHeaderSection .StoreTimingsActions a {
  color: white;
}
.d-none {
  display: none;
}
.p-none {
  pointer-events: none;
}

.c-none {
  cursor: not-allowed;
}
.ant-switch-checked {
  background-color: #000000;
}

.ant-spin-dot {
  position: absolute;
  left: 50%;
  top: 35%;
  z-index: 1000;
  /* height: 31px; */
  /* width: 31px; */
}

.se-dashboard-block .se-card.se-cardfulfilment .ant-table-content {
  min-height: 330px;
  /* max-height: 350px; */
  overflow: hidden;
}

.storeTimingTableBlock .ant-table-content:hover {
  overflow: auto;
}
.storeTimingTableBlock .ant-table-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.storeTimingTableBlock .ant-table-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

.storeTimingTableBlock .ant-table-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}
@media (max-width: 800px) {
  /*************************************************/
}

@media only screen and (min-device-width: 100px) and (max-device-width: 600px) {
  .store-heading {
    display: flex;
    flex-wrap: wrap;
  }
  .store-search {
    min-width: 80px !important;
  }
  .store-row {
    display: flex;
    flex-wrap: wrap;
  }
  .store-col {
    margin-top: 20px;
    min-width: 80px;
    height: 40px !important;
  }
}

.ant-modal.addstoreModalBox .ant-modal-body {
  padding: 16px 30px !important;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox {
  width: 60px !important;
  height: 60px !important;
  min-width: unset !important;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .imgBoxBlock
  .imgBox
  img {
  width: 100% !important;
  height: 100% !important;
  min-width: unset !important;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox {
  margin: 0 0 10px !important;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  form
  .fieldBox
  .ant-form-item {
  margin: 0 !important;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-heading {
  font-size: 26px;
}
.ant-modal.addstoreModalBox
  .ant-modal-body
  .AddStoreTimingsSection
  .modal-subtext {
  font-size: 12px !important;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .ant-collapse
  .forms-Collapse-item
  label {
  position: relative;

  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-modal.addstoreModalBox .ant-modal-body .ant-collapse .forms-Collapse-item {
  margin: 0 0 8px;
}

.ant-modal.addstoreModalBox
  .ant-modal-body
  .ant-collapse
  .forms-Collapse-item
  input {
  height: 30px;
}

.outer-outlet-block {
  padding: 5px;
}
.inner-outlet-block {
  height: 40px;
  padding: 5px;
  border: 1px solid;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 8px;
}

.inner-outlet-block label {
  font-weight: bold;
  margin-right: 5px;
}
