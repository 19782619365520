.ReportList-main {
  background: #edeef4;
  font-family: "Roboto", sans-serif;
}
.ReportList-main .content-container {
  padding: 25px 40px;
}
.ReportList-main .header {
  margin-bottom: 30px;
}
.ReportList-main .title {
  /* font-family: Heebo; */
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
}
.ReportList-main .search-box {
  height: 40px;
}
.ReportList-main .header .filterFieldBox {
  margin-right: 15px;
}
.ReportList-main .header button.exportReportBtn {
  padding: 10px 25px;
  color: #fff;
  background-color: #000;
  height: 40px;
  line-height: normal;
}
.ReportList-main .header .backLink {
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 10px;
  color: #000;
  display: inline-block;
}
.ReportList-main .header .backLink:hover {
  transform: translateX(-15px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 70px;
  transition: 0.5s;
}
.ReportList-main .ant-table-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.ReportList-main .ant-table-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

.ReportList-main .ant-table-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

.ReportList-main .content-container .CardRowSection {
  margin: 0 -20px;
}
.ReportList-main .content-container .CardRowSection:after {
  content: "";
  display: table;
  clear: both;
}
.ReportList-main .content-container .CardRowSection .cardColSection {
  width: calc(20% - 20px);
  padding: 0 10px;
  margin: 0px 10px 20px 10px;
}
.ReportList-main .content-container .cardSection {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 25px;
  /* text-align: center; */
  background-color: #f1f1f1;
  height: 100%;
}
.ReportList-main .content-container .cardSection .ant-card-body {
  padding: 0px;
}
.ReportList-main .content-container .cardSection .ant-card-body p.data {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}
.ReportList-main .content-container .cardSection .ant-card-body .circle {
  width: 45px;
  height: 45px;
  background-color: #ccc;
  border-radius: 50%;
  margin-bottom: 20px;
}

/****************************reportListTableBlock css**********************/

.ReportList-main .reportListTableBlock table thead tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.ReportList-main .reportListTableBlock table tbody tr {
  border-bottom: 1px solid #ccc;
}
/****************************************************************************/

@media screen and (max-width: 600px) {
  .ReportList-main .content-container .cardSection {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}
